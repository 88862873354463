import './App.css';

function App() {
  return (
    <div className="app">
      <div>
        <img className="profile-img" src="profile.png" alt="profile"></img>
      </div>
      <p>
        You've found me. <a className="app-link" href="mailto:sayhey@mikemccee.com">Say hey.</a>
      </p>
    </div>
  );
}

export default App;
